import {MenuItem, styled} from '@mui/material';

export const StyledMenuItem = styled(MenuItem)`
  background: white;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #666666;
  
  &.Mui-selected {
    color: #E20031;
    background: white;
    
    &:hover {
      background: #F3F3F3;
    }
  }

  &:hover {
    background: #F8F8F8;
  }
`;
