import { Box,styled } from "@mui/material";
import useAppDispatch from "../../../../hooks/useDispatch";
import useAppSelector from "../../../../hooks/useSelector";
import { PragungParametersLayout } from "../styles";
import { useMemo } from "react";
import { setKeyFont, setKeyFontSize, setKeyImage, setKeyLogo, setKeyLogoSize, setKeySideType, setKeyTextField, setKeysModalName } from "../../../../store/appKeysSlice";
import KeyCard from "./KeyCard/KeyCard";
import { SCROLL_CSS } from "../../../../constants/common";
import { IKeySideFont, IKeySideLogoSize, IKeySideName, IKeyType, IKeysModalsCommon } from "../../../../store/keysTypes";
import { useTranslation } from "react-i18next";
import { IKeysBlankNames } from "../../../../constants/pragerohlinge";

const Wrapper = styled(Box)`
  padding-top 5px;
  width: 100%;

  ${SCROLL_CSS};
`;

const PragungParameters = () => {
  const selectedKeyId = useAppSelector((state) => state.appKeys.selectedKeyId);
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);

  const { t } = useTranslation();

  const selectedKey = useMemo(() => {
    return Object.values(keys).flat().find((key) => key.id === selectedKeyId);
  }, [keys, selectedKeyId]);

  const selectedKeyIndex = useMemo(() => {
    if (selectedKey?.blank) {
      return keys[selectedKey.blank].findIndex((key) => key.id === selectedKeyId);
    }
    return 0;
  }, [keys, selectedKeyId]);

  const dispatch = useAppDispatch();

  const handleChangeType = (value: IKeyType, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeySideType({ keyIndex, side, type: value, blank }));
      });
      return;
    }
    dispatch(setKeySideType({ keyIndex: selectedKeyIndex, side, type: value, blank }));
  };

  const handleChangeTextField = (value: string, index: number, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeyTextField({ keyIndex, side, value, textIndex: index, blank }))
      });
      return;
    }
    dispatch(setKeyTextField({ keyIndex: selectedKeyIndex, side, value, textIndex: index, blank }))
  };

  const handleChangeFont = (value: IKeySideFont, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeyFont({ keyIndex, side, value, blank }));
      });
      return;
    }
    dispatch(setKeyFont({ keyIndex: selectedKeyIndex, side, value, blank }));
  };

  const handleChangeSize = (name: string, value: number, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeyFontSize({ keyIndex, side, name, value, blank }));
      });
      return;
    }
    dispatch(setKeyFontSize({ keyIndex: selectedKeyIndex, side, name, value, blank }));
  }

  const handleChangeLogo = (value: string, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeyLogo({ keyIndex, side, value, blank }));
      });
      return;
    }
    dispatch(setKeyLogo({ keyIndex: selectedKeyIndex, side, value, blank }));
  };

  const handleChangeLogoSize = (value: IKeySideLogoSize, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeyLogoSize({ keyIndex, side, value, blank }));
      });
      return;
    }
    dispatch(setKeyLogoSize({ keyIndex: selectedKeyIndex, side, value, blank }));
  };

  const handleScreenshot = (value: string, side: IKeySideName, blank: IKeysBlankNames) => {
    if (
      blank === IKeysBlankNames.FA
      || blank === IKeysBlankNames.OV
    ) {
      keys[blank].forEach((item, keyIndex) => {
        dispatch(setKeyImage({ keyIndex, side, value, blank }));
      });
      return;
    }
    dispatch(setKeyImage({ keyIndex: selectedKeyIndex, side, value, blank }));
  };

  const handleOpenModal = (value: IKeysModalsCommon) => {
    dispatch(setKeysModalName(value));
  };

  return (
    <Wrapper>
      {
        selectedKey &&
        <PragungParametersLayout>
          <KeyCard
            title={t('embossing.front-side')}
            text={selectedKey.front.text}
            type={selectedKey.front.type}
            font={selectedKey.front.font}
            size={selectedKey.front.size}
            logo={selectedKey.front.logo}
            blank={selectedKey.blank}
            profile={selectedKey.value}
            logoSize={selectedKey.front.logoSize}
            side={IKeySideName.FRONT}
            onChangeType={(value) => handleChangeType(value as IKeyType, IKeySideName.FRONT, selectedKey.blank)}
            onChangeTextField={(value, index) => handleChangeTextField(value, index, IKeySideName.FRONT, selectedKey.blank)}
            onChangeFont={(value) => handleChangeFont(value as IKeySideFont, IKeySideName.FRONT, selectedKey.blank)}
            onChangeSize={(name, value) => handleChangeSize(name, value, IKeySideName.FRONT, selectedKey.blank)}
            onChangeLogo={(value) => handleChangeLogo(value, IKeySideName.FRONT, selectedKey.blank)}
            onChangeLogoSize={(value) => handleChangeLogoSize(value, IKeySideName.FRONT, selectedKey.blank)}
            onOpenModal={(value) =>handleOpenModal(value)}
            onScreenshot={(value) => handleScreenshot(value, IKeySideName.FRONT, selectedKey.blank)}
          />

          <KeyCard
            title={t('embossing.back-side')}
            text={selectedKey.back.text}
            type={selectedKey.back.type}
            font={selectedKey.back.font}
            size={selectedKey.back.size}
            logo={selectedKey.back.logo}
            blank={selectedKey.blank}
            profile={selectedKey.value}
            logoSize={selectedKey.back.logoSize}
            side={IKeySideName.BACK}
            onChangeType={(value) => handleChangeType(value as IKeyType, IKeySideName.BACK, selectedKey.blank)}
            onChangeTextField={(value, index) => handleChangeTextField(value, index, IKeySideName.BACK, selectedKey.blank)}
            onChangeFont={(value) => handleChangeFont(value as IKeySideFont, IKeySideName.BACK, selectedKey.blank)}
            onChangeSize={(name, value) => handleChangeSize(name, value, IKeySideName.BACK, selectedKey.blank)}
            onChangeLogo={(value) => handleChangeLogo(value, IKeySideName.BACK, selectedKey.blank)}
            onChangeLogoSize={(value) => handleChangeLogoSize(value, IKeySideName.BACK, selectedKey.blank)}
            onOpenModal={handleOpenModal}
            onScreenshot={(value) => handleScreenshot(value, IKeySideName.BACK, selectedKey.blank)}
          />

        </PragungParametersLayout>
      }
    </Wrapper>
  )
};

export default PragungParameters;