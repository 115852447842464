import React from 'react';
import {styled, Box} from "@mui/material";
import {StyledNum} from "./styles";

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface IProps {
  index: number;
  isActive: boolean;
  text: string;
  disabled: boolean;
}

const TabLabel = ({ index, text, isActive, disabled }: IProps) => {
  return (
    <Wrapper>
      <StyledNum
        isactive={String(isActive)}
        disabled={disabled}
        className='tab-styled-num'
      >
        { index }
      </StyledNum>
      { text }
    </Wrapper>
  );
};

export default TabLabel;
