import { LanguageValues } from "../constants/language";
import {LanguageStateType} from "./types";
import {createSlice} from "@reduxjs/toolkit";

const initialState: LanguageStateType = {
  value: LanguageValues.DE,
}

export const languageSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const {
  setLanguage,
} = languageSlice.actions;

export default languageSlice.reducer;
