import {Box, css, styled} from "@mui/material";

export const PragungLayout = styled(Box)`
  width: 100%;
  min-height: 360px;
  display: grid;
  grid-template-columns: 224px 1fr;
  grid-column-gap: 12px;
`;

export const PragungOptionsLayout = styled(Box)`
  padding: 16px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 16px;
  margin-bottom: 16px;
`;

export const PragungParametersLayout = styled(Box)`
  display: flex;
  align-items: start;
  width: 100%;
  gap: 16px;
`;

interface SelectorProfilesProps {
  isselected: string;
  showhover: string;
}

export const PragungSelectorProfiles = styled(Box)<SelectorProfilesProps>`
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 16px;
  transition: background-color .2s ease-in, border-color .2s ease-in, padding .2s ease-in;
  cursor: ${props => props.showhover === 'true' ? 'pointer' : 'default'};
  margin-bottom: 8px;

  ${props => props.isselected === 'true' && css`
    padding: 8px;
    background-color: #FFFFFF;
    border-color: #E20031;
  `}

  &:hover {
    ${props => props.showhover === 'true' && css`
      padding: 8px;
      background-color: #FFFFFF;
      border-color: #E20031;
    `}
  }
`;
