import TextStepName from "../../../UI/Typography/TextStepName";
import DoorSelector from "./DoorSelector";
import {TurenContentLayout} from "../styles";
import DoorParameters from "./DoorParameters";
import { useTranslation } from "react-i18next";

const PageLayoutMain = () => {
  const { t } = useTranslation();

  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('turen.title')}
      </TextStepName>
      <TurenContentLayout>
        <DoorSelector />
        <DoorParameters />
      </TurenContentLayout>
    </div>
  );
};

export default PageLayoutMain;
