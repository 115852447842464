import faImage from '../assets/img/keys/profiles/FA-Kopf.png';
import ovImage from '../assets/img/keys/profiles/OV-Kopf.png';
import { IKeysBlankNames } from '../constants/pragerohlinge';
import { ORIGINAL_KEY_IMAGES } from '../constants/generateImage';

interface IProps {
  blank: string;
  profile: string;
}

const getKeyImage = ({ profile, blank }: IProps) => {
  if (blank === IKeysBlankNames.FA) {
    return {
      image: faImage,
    };
  }

  if (blank === IKeysBlankNames.OV) {
    return {
      image: ovImage,
    }
  }

  return {
    image: ORIGINAL_KEY_IMAGES[profile] || ORIGINAL_KEY_IMAGES.abu17d,
  };
};

export default getKeyImage;