import TextStepName from "../../UI/Typography/TextStepName";
import {CardsContainer} from "./styles";
import ProduktCard from "./components/ProduktCard";
import {PRODUKT_CARDS} from "../../../constants/produkt";
import useAppSelector from "../../../hooks/useSelector";
import useAppDispatch from "../../../hooks/useDispatch";
import {setConfigProduct, setDoorHebelvariationen, setProductType} from "../../../store/configSlice";
import { useTranslation } from 'react-i18next';
import { K10_TUREN_VARIANTS, K6_TUREN_VARIANTS, T250_TUREN_VARIANTS } from "../../../constants/turen";

const Produkt = () => {
  const { t } = useTranslation();
  const productId = useAppSelector((state) => state.config.productId);
  const currentProductType = useAppSelector((state) => state.config.productType);
  const doors = useAppSelector((state) => state.config.doors);

  const dispatch = useAppDispatch();

  const handleChange = (id: string, type: string) => {
    dispatch(setConfigProduct(id));
    dispatch(setProductType(type));

    if (doors.length) {
      const hebelvariationenDefaultValue = (id === '1'
        ? T250_TUREN_VARIANTS
        : id === '2'
        ? K10_TUREN_VARIANTS
        : K6_TUREN_VARIANTS)[0].value;

      doors.forEach((door, doorIndex) => {
        dispatch(setDoorHebelvariationen({ doorIndex, lange: hebelvariationenDefaultValue }));
      });
    }
  }

  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('product.title')}
      </TextStepName>
      <CardsContainer>
        {
          PRODUKT_CARDS.map((item) => <ProduktCard
            key={item.id}
            data={item}
            currentProductType={currentProductType}
            isSelected={item.id === productId}
            onClick={(type: string) => handleChange(item.id, type)}
          />)
        }
      </CardsContainer>
    </div>
  );
};

export default Produkt;
