import WorkArea from "../../components/Layouts/WorkArea/WorkArea";
import {Box, styled} from "@mui/material";
import Content from "../../components/Layouts/Content/Content";
import useAppSelector from "../../hooks/useSelector";
import Pragerohlinge from "../../components/KeysSteps/Pragerohlinge";
import TabSwitchPanel from "../../components/Layouts/TabSwitchPanel/TabSwitchPanel";
import Paginator from "../../components/Layouts/Paginator/Paginator";
import { TABS_KEYS_DATA } from "../../constants/common";
import useKeysPaginator from "../../hooks/useKeysPaginator";
import { SyntheticEvent, useEffect, useMemo } from "react";
import Pragung from "../../components/KeysSteps/Pragung";
import KeysModalController from "../../components/Layouts/KeysModalController.tsx/KeysModalController";
import Zusammenfassung from "../../components/KeysSteps/Zusammenfassung";
import Thanks from "../../components/Layouts/Thanks/Thanks";
import Anfrage from "../../components/Layouts/Anfrage";
import useAppDispatch from "../../hooks/useDispatch";
import { resetContactForm, setLoading, setThanksWindow } from "../../store/contactSlice";
import { httpClient } from "../../client/httpClient";
import useKeysPrepareConfig from "../../hooks/useKeysPrepareConfig";
import useDocumentTitle from "../../hooks/useDocumetTitle";

const Wrapper = styled(Box)`
  width: 1150px;
  margin: 0 auto 32px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

interface IProps {
	title: string;
}

const KeysView = ({ title }: IProps) => {
	const thanks = useAppSelector((state) => state.contact.thanksWindow);
  const page = useAppSelector((state) => state.appKeys.page);
	const enabledTabIds = useAppSelector((state) => state.appKeys.enabledTabIds);
	const { getConfig } = useKeysPrepareConfig();
	const dispatch = useAppDispatch();

	useDocumentTitle(title);

	useEffect(() => {
    dispatch(resetContactForm());
  }, []);

	const {
		onChangeTab,
		onBack,
		onForward,
		disablePagination,
		disableForward,
	} = useKeysPaginator();

	const handleSubmit = () => {
		setLoading(true);

		httpClient.post('configurator/keys', getConfig())
			.then(() => {
				dispatch(setThanksWindow(true));
			})
			.catch((e) => {
				setLoading(false);
			});
	};

	const getContent = () => {
    switch (page) {
      case 1: return <Pragerohlinge />;
			case 2: return <Pragung />;
			case 3: return <Zusammenfassung />;
			case 4: return <Anfrage onSubmit={handleSubmit} />
      default: return null;
    }
  };

	const getHeight = useMemo(() => {
		if (page === 1) {
			return '680px';
		}
		return '630px';
	}, [page]);

	const getMaxHeight = useMemo(() => {
		if (page === 2 || page === 4) {
			return 'unset';
		}

		return '78vh';
	}, [page]);

	return (
		<Wrapper>
			{
				thanks
					? <Thanks />
					: <WorkArea
							maxHeight={getMaxHeight}
							minHeight={getHeight}
						>
							<TabSwitchPanel
								tabsData={TABS_KEYS_DATA}
								page={page}
								enabledTabIds={enabledTabIds}
								onChangeTab={(_: SyntheticEvent<Element, Event>, newVal: number) => onChangeTab(newVal)}
								disablePagination={disablePagination}
								disableForward={disableForward}
							/>
							<Content>
								{getContent()}
							</Content>
							<Paginator
								tabsData={TABS_KEYS_DATA}
								page={page}
								disablePagination={disablePagination}
								disableForward={disableForward}
								onBack={onBack}
								onForward={onForward}
							/>
						</WorkArea>
			}
			<KeysModalController />
		</Wrapper>
	)
};

export default KeysView;