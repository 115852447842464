import { Box, IconButton, styled } from "@mui/material";
import { ZusammenfassungItemBody, ZusammenfassungItemHeader, ZusammenfassungItemImage } from "../styles";
import TextLabelBlue from "../../../UI/Typography/TextLabelBlue";
import TextLabel from "../../../UI/Typography/TextLabel";
import Select from "../../../UI/Select";
import { FA_OV_MENGE_RANGES, MENGE_RANGES } from "../../../../constants/zusammenfassung";
import svgTooltip from '../../../../assets/img/tooltip.svg';
import { IKeysModalsCommon } from "../../../../store/keysTypes";
import Button from "../../../UI/Button";
import editIcon from '../../../../assets/img/edit-blue.svg';
import deleteIcon from '../../../../assets/img/trash-blue.svg';
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IKeysBlankNames } from "../../../../constants/pragerohlinge";
import getKeyImage from "../../../../tools/getGetKeyImage";

const Wrapper = styled(Box)`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

interface IProps {
  name: string;
  menge: string;
  blank: string;
  profile: string;
  frontImage: string;
  backImage: string;
  value: string;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onChangeMenge: (value: string) => void;
  onClickTooltip: (value: IKeysModalsCommon, blank: string) => void;
}

const ZusammenfassungItem = ({
  name,
  menge,
  blank,
  profile,
  value,
  frontImage,
  backImage,
  onChangeMenge,
  onClickTooltip,
  onClickEdit,
  onClickDelete
}: IProps) => {
  const { image: defaultImage } = getKeyImage({ blank, profile });
  const { t } = useTranslation();

  const getFrontImage = useMemo(() => {
    if (!frontImage) {
      return defaultImage;
    }
    return frontImage;
  }, [blank, profile]);

  const getBackImage = useMemo(() => {
    if (!backImage) {
      return defaultImage;
    }
    return backImage;
  }, [blank, profile]);

  const getMengeRanges = useMemo(() => {
    if (blank === IKeysBlankNames.FA || blank === IKeysBlankNames.OV) {
      return FA_OV_MENGE_RANGES;
    }

    return MENGE_RANGES;
  }, [blank, profile]);

  return (
    <Wrapper>
      <ZusammenfassungItemHeader>
        <Box>
          <TextLabelBlue sx={{ fontWeight: '400', marginRight: '8px' }}>
            {t(`keys.${blank}`)}
          </TextLabelBlue>

          <TextLabel>
            {name}
          </TextLabel>
        </Box>
        
        {
          value !== 'flafa'&&
          <Box>
            <img
              src={`${process.env.PUBLIC_URL}/black-key-profiles/${value.toUpperCase()}.jpg`}
              alt="key"
            />
          </Box>
        }

        <Box sx={{ marginLeft: 'auto' }}>
          <Select
            options={getMengeRanges}
            value={menge}
            placeholder={'Menge'}
            onChange={(e) => onChangeMenge(e.target.value as string)}
          />
        </Box>

        <Box sx={{ marginRight: '12px' }}>
          <IconButton
            onClick={() => onClickTooltip(IKeysModalsCommon.MODAL_MENGE, blank)}
          >
            <img
              src={svgTooltip}
              alt='(i)'
            />
          </IconButton>
        </Box>

        <Box>
          <Button
            variant='second'
            sx={{ padding: '8px', minWidth: '40px', marginRight: '8px' }}
            onClick={onClickEdit}
          >
            <img src={editIcon} alt='(i)' />
          </Button>
        </Box>

        <Box>
          <Button
            variant='second'
            sx={{ padding: '8px', minWidth: '40px', marginRight: '8px' }}
            onClick={onClickDelete}
          >
            <img src={deleteIcon} alt='(i)' />
          </Button>
        </Box>
      </ZusammenfassungItemHeader>

      <ZusammenfassungItemBody>
        <Box sx={{ flex: '0 0 50%' }}>
          <TextLabel sx={{ marginBottom: '24px' }}>
            {t('key-summary.front-side')}
          </TextLabel>

          <ZusammenfassungItemImage>
            <img src={getFrontImage} alt='(i)' />
          </ZusammenfassungItemImage>
        </Box>

        <Box sx={{ flex: '0 0 50%' }}>
          <TextLabel sx={{ marginBottom: '24px' }}>
            {t('key-summary.back-side')}
          </TextLabel>

          <ZusammenfassungItemImage>
            <img src={getBackImage} alt='(i)' />
          </ZusammenfassungItemImage>
        </Box>
      </ZusammenfassungItemBody>
    </Wrapper>
  )
};

export default ZusammenfassungItem;