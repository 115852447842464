import { IKeysBlankNames } from "./pragerohlinge";

interface IKeyFontSizes {
  [name: string|IKeysBlankNames]: {
    [name: string]: {
      defaultMax: number;
      startDecrease: number;
    }
  }
}

export const KEY_FONT_SIZES: IKeyFontSizes = {
  [IKeysBlankNames.FA]: {
    A: {
      defaultMax: 26,
      startDecrease: 18,
    },
    B: {
      defaultMax: 26,
      startDecrease: 18,
    },
    C: {
      defaultMax: 26,
      startDecrease: 18,
    },
    D: {
      defaultMax: 26,
      startDecrease: 18,
    }
  },
  [IKeysBlankNames.OV]: {
    A: {
      defaultMax: 26,
      startDecrease: 18,
    },
    B: {
      defaultMax: 26,
      startDecrease: 15,
    },
    C: {
      defaultMax: 26,
      startDecrease: 12,
    },
  },
  'abu-17d': {
    A: {
      defaultMax: 26,
      startDecrease: 14,
    },
    B: {
      defaultMax: 26,
      startDecrease: 14,
    },
    C: {
      defaultMax: 26,
      startDecrease: 14,
    },
    D: {
      defaultMax: 26,
      startDecrease: 14,
    }
  },
  'abu-86d': {
    A: {
      defaultMax: 26,
      startDecrease: 13,
    },
    B: {
      defaultMax: 26,
      startDecrease: 13,
    },
    C: {
      defaultMax: 26,
      startDecrease: 13,
    },
    D: {
      defaultMax: 26,
      startDecrease: 13,
    }
  },
  'bk-7d': {
    A: {
      defaultMax: 26,
      startDecrease: 13,
    },
    B: {
      defaultMax: 26,
      startDecrease: 13,
    },
    C: {
      defaultMax: 26,
      startDecrease: 13,
    },
    D: {
      defaultMax: 26,
      startDecrease: 13,
    }
  },
  'ce-53d': {
    A: {
      defaultMax: 26,
      startDecrease: 20,
    },
    B: {
      defaultMax: 26,
      startDecrease: 20,
    },
  },
  'wil-66d': {
    A: {
      defaultMax: 26,
      startDecrease: 16,
    },
    B: {
      defaultMax: 26,
      startDecrease: 16,
    },
    C: {
      defaultMax: 26,
      startDecrease: 12,
    },
  },
  'win-22': {
    A: {
      defaultMax: 26,
      startDecrease: 14,
    },
    B: {
      defaultMax: 26,
      startDecrease: 13,
    },
    C: {
      defaultMax: 26,
      startDecrease: 13,
    },
    D: {
      defaultMax: 26,
      startDecrease: 12,
    }
  },
};