import {ChangeEvent, HTMLInputTypeAttribute} from 'react';
import {styled, SxProps, TextField} from "@mui/material";

interface WrapperProps {
  width?: string;
  inputHeight? : string;
}

const Wrapper = styled(TextField)<WrapperProps>`
  .MuiInputBase-root {
    box-sizing: border-box;
    width: ${props => props.width ? props.width : '100%'};
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    font-family: Helvetica, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    transition: border .2s ease-in;
    
    input {
      padding: 0 0 0 8px;
      text-align: left;
      height: ${props => props.inputHeight || 'auto'}
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    &.Mui-focused {
      border: 2px solid #06326E;
      color: #06326E;
    }

    &.Mui-error {
      border: 2px solid #E20031;
    }
  }
  
  fieldset {
    display: none;
  }
`;

export interface ITextFieldProps {
  value: number | string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute;
  min?: number;
  max?: number;
  width?: string;
  inputHeight?: string;
  sx?: SxProps;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

const CustomTextField = ({
  value,
  onChange,
  type,
  min,
  max,
  width,
  sx,
  maxLength,
  placeholder,
  disabled,
  error = false,
  onFocus,
  onBlur
}: ITextFieldProps) => {
  return (
    <Wrapper
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={type || 'text'}
      value={value}
      disabled={disabled}
      InputProps={{
        inputProps: {
          min: min,
          max: max,
          maxLength,
          placeholder,
        },
      }}
      width={width}
      error={error}
      sx={sx}
    />
  );
};

export default CustomTextField;
