import { Box, styled } from "@mui/material";
import TextStepName from "../../UI/Typography/TextStepName";
import { ZusammenfassungLayout } from "./styles";
import ZusammenfassungList from "./components/ZusammenfassungList";
import ZusammenfassungParameters from "./components/ZusammenfassungParameters";
import FormInput from "../../UI/FormInput";
import TextArea from "../../UI/TextArea";
import ImageFrame from "../../UI/ImageFrame/ImageFrame";
import useAppSelector from "../../../hooks/useSelector";
import useAppDispatch from "../../../hooks/useDispatch";
import { setComment } from "../../../store/contactSlice";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import Loader from "../../UI/Loader/Loader";
import { IKey, IKeySideName } from "../../../store/keysTypes";
import { setKeyImage } from "../../../store/appKeysSlice";
import { FA_ORIGINAL_KEYS_IMAGES } from "../../../constants/generateImage";

const Wrapper = styled(Box)`
  padding-bottom: 55px;
`;

const HIDDEN_INPUT_KEYS_NAMES = [
  'basi-bmfa',
  'basi-vbfa',
  'abu-91fa',
  'basi-vfa',
  'ci-48fa',
  'is-15fa',
  'flafa',
];

const Zusammenfassung = () => {
  const { t } = useTranslation();
  const comment = useAppSelector((state) => state.contact.comment);
  const keys = useAppSelector((state) => state.appKeys.selectedKeyTypes);
  const [ready, setReady] = useState<boolean>(false);
  const [currentFrame, setCurrentFrame] = useState<IKey | null>(null);
  const [isFrontSideReady, setFrontSideReady] = useState<boolean>(false);
  const [isBackSideReady, setBackSideReady] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleChangeComment = (value: string) => {
    dispatch(setComment(value));
  };

  const hiddenKeysArray = useMemo(() => {
    return Object.values(keys).flat().filter(item => HIDDEN_INPUT_KEYS_NAMES.includes(item.value));
  }, [keys]);

  const getImage = useMemo(() => {
    if (currentFrame) {
      return FA_ORIGINAL_KEYS_IMAGES[currentFrame.value];
    }
    return '';
  }, [currentFrame]);

  const handleScreenshot = (value: string, side: IKeySideName) => {
    if (currentFrame) {
      const keyIndex = keys[currentFrame?.blank].findIndex(key => key.id === currentFrame.id);
      dispatch(setKeyImage({ keyIndex, side, value, blank: currentFrame.blank }));
      
      if (side === IKeySideName.FRONT) {
        setFrontSideReady(true);
      } else {
        setBackSideReady(true);
      }
    }
  }

  useEffect(() => {
    if (!hiddenKeysArray.length) {
      setReady(true);
      return;
    }

    if (isFrontSideReady && isBackSideReady && currentFrame) {
      const currentFrameIndex = hiddenKeysArray.findIndex(key => key.id === currentFrame.id) + 1;

      if (hiddenKeysArray[currentFrameIndex]) {
        setCurrentFrame(hiddenKeysArray[currentFrameIndex]);
        setFrontSideReady(false);
        setBackSideReady(false);
      } else {
        setCurrentFrame(null);
        setReady(true);
      }
      return;
    }

    if (!currentFrame && !ready) {
      setCurrentFrame(hiddenKeysArray[0]);
      return;
    }

    if (!ready) {
      return;
    }

    setReady(true);
  }, [currentFrame, hiddenKeysArray, isFrontSideReady, isBackSideReady]);

  return (
    <Wrapper>
      <Loader
        loading={!ready}
      />
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('key-summary.title')}
      </TextStepName>
      <ZusammenfassungLayout>
        <ZusammenfassungList />
        {
          ready
            ? <ZusammenfassungParameters />
            : <div />

        }
        <Box>
          <FormInput
            label=''
            sx={{ gap: 'unset' }}
          >
            <TextArea
              value={comment}
              minRows={20}
              maxRows={20}
              placeholder={t('order.comment')}
              onChange={(e) => handleChangeComment(e.target.value as string)}
            />
          </FormInput>
        </Box>
      </ZusammenfassungLayout>
      
      {
        (currentFrame &&
        hiddenKeysArray.length > 0) &&
        <Box
          sx={{display: 'none'}}
        >
          <ImageFrame
            image={getImage}
            text={currentFrame.front.text}
            blank={currentFrame.value}
            logo={currentFrame.front.logo}
            font={currentFrame.front.font}
            size={currentFrame.front.size}
            type={currentFrame.front.type}
            logoSize={currentFrame.front.logoSize}
            side={IKeySideName.FRONT}
            onScreenshot={(value: string) => handleScreenshot(value, IKeySideName.FRONT)}
          />
          <ImageFrame
            image={getImage}
            text={currentFrame.back.text}
            blank={currentFrame.value}
            logo={currentFrame.back.logo}
            font={currentFrame.back.font}
            size={currentFrame.back.size}
            type={currentFrame.back.type}
            logoSize={currentFrame.back.logoSize}
            side={IKeySideName.BACK}
            onScreenshot={(value: string) => handleScreenshot(value, IKeySideName.BACK)}
          />
        </Box>
      }
    </Wrapper>
  )
}

export default Zusammenfassung;