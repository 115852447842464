import React, {ChangeEvent, useCallback, useMemo} from 'react';
import {Box, SelectChangeEvent, styled} from "@mui/material";
import FormInput from "../../../UI/FormInput";
import useAppSelector from "../../../../hooks/useSelector";
import useAppDispatch from "../../../../hooks/useDispatch";
import {setDoorBugelhohe, setDoorHebelvariationen, setDoorLangeAusen, setDoorLangeInnen, setDoorName, setDoorType} from "../../../../store/configSlice";
import {setModalName} from "../../../../store/appSlice";
import TextField from "../../../UI/TextField";
import Select from "../../../UI/Select";
import {BUGELHOHE_TUREN_LANGES, K10_TUREN_VARIANTS, K6_TUREN_VARIANTS, T250_TUREN_VARIANTS, TUREN_ZYLINDER_TYPES} from "../../../../constants/turen";
import {BlueprintBox, TurenParametersLayout} from "../styles";
import TextModalHeading from "../../../UI/Typography/TextModalHeading";
import profileCylinderImgEn from '../../../../assets/img/profile-cylinder-en.jpg';
import profileCylinderImgDe from '../../../../assets/img/profile-cylinder-de.jpg';
import useLangeOptions from "../../../../hooks/useLangeOptions";
import { useTranslation } from 'react-i18next';
import downloadFile from '../../../../tools/downloadFile';

const Wrapper = styled(Box)`
  width: 100%;
  height: 360px;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  gap: 16px;
`;

const DoorParameters = () => {
  const { t } = useTranslation();
  const language = useAppSelector((state) => state.language.value);
  const doors = useAppSelector((state) => state.config.doors);
  const selectedDoorId = useAppSelector((state) => state.app.selectedDoorId);
  const productId = useAppSelector((state) => state.config.productId);

  const door = useMemo(() => {
    return doors.find((k) => k.id === selectedDoorId);
  }, [doors, selectedDoorId]);
  const doorIndex = useMemo(() => {
    return doors.findIndex((k) => k.id === selectedDoorId);
  }, [doors, selectedDoorId]);
  const showLange = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find((type) => type.value === door?.type)?.showLange;
  }, [door]);
  const showBugelhohe = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find((type) => type.value === door?.type)?.showBugelhohe;
  }, [door]);
  const showHebelvariationen = useMemo(() => {
    return TUREN_ZYLINDER_TYPES.find((type) => type.value === door?.type)?.showHebelvariationen;
  }, [door]);
  const hebelvariationenVariations = useMemo(() => {
    if (productId === '1') {
      return T250_TUREN_VARIANTS;
    }
    if (productId === '2') {
      return K10_TUREN_VARIANTS;
    }
    return K6_TUREN_VARIANTS;
  }, [productId]);
  const getProfileCylinderImage = useMemo(() => {
    if (language === 'EN') {
      return profileCylinderImgEn;
    }
    return profileCylinderImgDe;
  }, [language]);

  const dispatch = useAppDispatch();

  const handleChangeLangeAusen = useCallback((value: string) => {
    if (!doors.length) {
      return;
    }
    dispatch(setDoorLangeAusen({
      doorIndex,
      lange: value,
    }));
  }, [doorIndex]);

  const handleChangeLangeInnen = useCallback((value: string) => {
    if (!doors.length) {
      return;
    }
    dispatch(setDoorLangeInnen({
      doorIndex,
      lange: value,
    }));
  }, [doorIndex]);

  const handleChangeBugelhohe = useCallback((value: string) => {
    if (!doors.length) {
      return;
    }

    dispatch(setDoorBugelhohe({
      doorIndex,
      lange: value,
    }));
  }, [doorIndex]);

  const handleChangeHebelvariationen = useCallback((value: string) => {
    if (!doors.length) {
      return;
    }

    dispatch(setDoorHebelvariationen({
      doorIndex,
      lange: value,
    }));
  }, [doorIndex]);

  const {
    LangesInnenOptions,
    LangesAusenOptions,
    isKnaufseite,
  } = useLangeOptions(door, handleChangeLangeAusen, handleChangeLangeInnen);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setDoorName({
      doorIndex,
      name: e.target.value,
    }));
  }

  const handleChangeType = (e: SelectChangeEvent<unknown>) => {
    dispatch(setDoorType({
      doorIndex,
      type: e.target.value,
    }));
    dispatch(setDoorLangeAusen({
      doorIndex,
      lange: LangesAusenOptions[0].value,
    }));
    dispatch(setDoorLangeInnen({
      doorIndex,
      lange: LangesInnenOptions[0].value,
    }));
  }

  const onClickTooltip = (modalName: string) => {
    dispatch(setModalName(modalName));
  }

  const handleBlueprintClick = () => {
    const href = process.env.PUBLIC_URL + (language === 'EN' ? '/pdf/profile-cylinder-en.pdf' : '/pdf/profile-cylinder-de.pdf');
    downloadFile(href);
  };

  return (
    <Wrapper>
      {
        door ?
          <TurenParametersLayout>
            <div>
              <FormInput
                label={t('turen.door-name')}
                sx={{
                  maxWidth: '438px',
                  marginBottom: '24px',
                }}
                tooltipAction={() => onClickTooltip('doorName')}
              >
                <TextField
                  value={door.name}
                  error={!door.name}
                  onChange={handleChangeName}
                  maxLength={16}
                />
              </FormInput>

              <FormInput
                label={t('turen.cylinder-type')}
                sx={{
                  maxWidth: '438px',
                  marginBottom: '24px',
                }}
                tooltipAction={() => onClickTooltip('zylindertypen')}
              >
                <Select
                  options={TUREN_ZYLINDER_TYPES}
                  translate={true}
                  value={door.type}
                  onChange={handleChangeType}
                />
              </FormInput>

              {
                showLange &&
                <>
                  <FormInput
                    label={isKnaufseite ? `${t('turen.inside-length')} (${t('turen.knob-side')})` : t('turen.inside-length')}
                    sx={{
                      maxWidth: '438px',
                      marginBottom: '24px',
                    }}
                  >
                    <Select
                      options={LangesInnenOptions}
                      value={door.langeInnen}
                      onChange={(e) => handleChangeLangeInnen(e.target.value as string)}
                    />
                  </FormInput>

                  <FormInput
                    label={t('turen.outside-length')}
                    sx={{
                      maxWidth: '438px',
                    }}
                  >
                    <Select
                      options={LangesAusenOptions}
                      value={door.langeAusen}
                      onChange={(e) => handleChangeLangeAusen(e.target.value as string)}
                    />
                  </FormInput>
                </>
              }
              {
                showBugelhohe &&
                <>
                  <FormInput
                    label={t('turen.ironing-height')}
                    sx={{
                      maxWidth: '438px',
                      marginBottom: '24px',
                    }}
                  >
                    <Select
                      options={BUGELHOHE_TUREN_LANGES}
                      value={door.bugelhohe}
                      onChange={(e) => handleChangeBugelhohe(e.target.value as string)}
                    />
                  </FormInput>
                </>
              }
              {
                showHebelvariationen &&
                <>
                  <FormInput
                    label={t('turen.leverage-variations')}
                    sx={{
                      maxWidth: '438px',
                      marginBottom: '24px',
                    }}
                  >
                    <Select
                      options={hebelvariationenVariations}
                      value={door.hebelvariationen}
                      translate={true}
                      onChange={(e) => handleChangeHebelvariationen(e.target.value as string)}
                    />
                  </FormInput>
                </>
              }
            </div>

            <div>
              <TextModalHeading
                sx={{ marginBottom: '16px', textAlign: 'center' }}
              >
                {t('turen.help')}
              </TextModalHeading>
              <BlueprintBox>
                <img
                  src={getProfileCylinderImage}
                  alt=''
                  onClick={handleBlueprintClick}
                />
              </BlueprintBox>
            </div>
          </TurenParametersLayout>
          : null
      }
    </Wrapper>
  );
};

export default DoorParameters;
