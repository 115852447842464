import {Box, styled} from "@mui/material";
import WorkArea from "../../components/Layouts/WorkArea/WorkArea";
import ModalController from "../../components/Layouts/ModalController/ModalController";
import useAppSelector from "../../hooks/useSelector";
import Thanks from "../../components/Layouts/Thanks/Thanks";
import TabSwitchPanel from "../../components/Layouts/TabSwitchPanel/TabSwitchPanel";
import Content from "../../components/Layouts/Content/Content";
import Paginator from "../../components/Layouts/Paginator/Paginator";
import Produkt from "../../components/LocksSteps/Produkt/Produkt";
import Turen from "../../components/LocksSteps/Turen/Turen";
import Benutzer from "../../components/LocksSteps/Benutzer/Benutzer";
import Schliesmatrix from "../../components/LocksSteps/Schließplan/Schließplan";
import Anfrage from "../../components/Layouts/Anfrage/Anfrage";
import Project from "../../components/LocksSteps/Project";
import usePaginator from "../../hooks/usePaginator";
import {TABS_DATA} from "../../constants/common";
import { SyntheticEvent, useEffect } from "react";
import useAppDispatch from "../../hooks/useDispatch";
import { resetContactForm, setLoading, setThanksWindow } from "../../store/contactSlice";
import { httpClient } from "../../client/httpClient";
import usePrepareConfig from "../../hooks/usePrepareConfig";
import Zusammenfassung from "../../components/LocksSteps/Zusammenfassung";
import useDocumentTitle from "../../hooks/useDocumetTitle";

const Wrapper = styled(Box)`
  width: 1150px;
  margin: 0 auto 32px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

interface IProps {
	title: string;
}

const LocksView = ({ title }: IProps) => {
  const thanks = useAppSelector((state) => state.contact.thanksWindow);
  const page = useAppSelector((state) => state.app.page);
  const enabledTabIds = useAppSelector((state) => state.app.enabledTabIds);
  const { getConfig } = usePrepareConfig();

  useDocumentTitle(title);

  const dispatch = useAppDispatch();

	useEffect(() => {
    dispatch(resetContactForm());
  }, []);

  const {
    onBack,
    onForward,
    onChangeTab,
    disablePagination,
    disableForward,
    onDownloadPdf,
  } = usePaginator();

  const handleSubmit = () => {
    setLoading(true);

    httpClient.post('configurator', getConfig())
      .then(() => {
        dispatch(setThanksWindow(true));
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getContent = () => {
    switch (page) {
      case 1: return <Produkt />;
      case 2: return <Project />;
      case 3: return <Turen />;
      case 4: return <Benutzer />;
      case 5: return <Schliesmatrix />;
      case 6: return <Zusammenfassung />;
      case 7: return <Anfrage onSubmit={handleSubmit} />;
      default: return null;
    }
  };

  return (
    <Wrapper>
      {
        thanks
          ? <Thanks />
          : <WorkArea
              maxHeight={page === 7 || page === 6 ? 'unset' : '78vh'}
            >
              <TabSwitchPanel
                tabsData={TABS_DATA}
                page={page}
                enabledTabIds={enabledTabIds}
                onChangeTab={(_: SyntheticEvent<Element, Event>, newVal: number) => onChangeTab(_, newVal)}
                disablePagination={disablePagination}
                disableForward={disableForward}
              />
              <Content>
                {getContent()}
              </Content>
              <Paginator
                tabsData={TABS_DATA}
                page={page}
                onBack={onBack}
                onForward={onForward}
                onDownloadPdf={() => onDownloadPdf(process.env.PUBLIC_URL + '/pdf/schliessplan_2022.pdf')}
                disablePagination={disablePagination}
                disableForward={disableForward}
              />
            </WorkArea>
      }
      <ModalController />
    </Wrapper>
  );
};

export default LocksView;
