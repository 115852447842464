import React from 'react';
import {Box, styled} from "@mui/material";
import useAppDispatch from './hooks/useDispatch';
import { setLanguage } from './store/languageSlice';
import LocksPage from './pages/LocksPage/LocksPage';
import KeysPage from './pages/KeysPage/KeysPage';

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

function App() {
  const dispath = useAppDispatch();

  if (window.localStorage.getItem('i18nextLng')) {
    dispath(setLanguage(window.localStorage.getItem('i18nextLng')?.toUpperCase()));
  }

  return (
    <Wrapper>
      {
        process.env.REACT_APP_DEV_ENV_KEYS === '1' 
          ? <KeysPage title={'BASI Prägerohling Konfigurator'} />
          : <LocksPage title={'BASI Schließanlagen - Konfigurator'} />
      }
    </Wrapper>
  );
}

export default App;
