import {useEffect, useState} from 'react';
import useAppSelector from "../../../../hooks/useSelector";
import Card from "../../../UI/Card";
import {Box, styled} from "@mui/material";
import useAppDispatch from "../../../../hooks/useDispatch";
import {setModalName, setSelectedDoorId} from "../../../../store/appSlice";
import {MAX_DOORS, SCROLL_CSS} from "../../../../constants/common";
import AddCardButton from "../../../UI/Card/AddCardButton";
import {addDoor, setDoorVisited, setDraftDoor, copyDoor, setConfigDoors} from "../../../../store/configSlice";
import { v4 } from 'uuid';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import drag from '../../../../assets/img/drag.svg';
import Tooltip from '@mui/material/Tooltip';

import generateDoors from '../../../../tools/generateDoors';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(Box)`
  min-height: 360px;
  max-height: 54vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;

  .draggable-item {
    display: flex;
  }

  .draggable-item__drag {
    align-items: center;
    margin-left: auto;
    margin-bottom: 8px;
  }

  ${SCROLL_CSS};
`;

const DoorSelector = () => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string|number>(1);
  const doors = useAppSelector((state) => state.config.doors);
  const selectedId = useAppSelector((state) => state.app.selectedDoorId);
  const language = useAppSelector((state) => state.language.value);
  const productId = useAppSelector((state) => state.config.productId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedId) {
      dispatch(setSelectedDoorId(doors[0]?.id || null));
      return;
    }

    const element = document.getElementById(`cardId_${selectedId}`);
    element?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [selectedId]);

  useEffect(() => {
    if (+amount > MAX_DOORS - doors.length) {
      setAmount(MAX_DOORS - doors.length);
    }
  }, [doors]);

  const handleDoorSelect = (id: string) => {
    const doorIndex = doors.findIndex((door) => door.id === id);

    dispatch(setSelectedDoorId(id));
    dispatch(setDoorVisited({
      doorIndex,
      visited: true,
    }));
  }

  const handleRemoveDoor = () => {
    dispatch(setModalName('removeDoor'));
  }

  const handleAddDoor = () => {
    if (+amount <= 0) {
      return;
    }

    dispatch(setDraftDoor(null));
    if (amount === 1) {
      const newId = v4();
      dispatch(setSelectedDoorId(newId));
      dispatch(addDoor({
        visited: true,
        id: newId,
        language,
      }));
      return;
    }

    const newDoorsArray = generateDoors(+amount, doors, language, productId);
    dispatch(setConfigDoors(newDoorsArray));
    dispatch(setSelectedDoorId(newDoorsArray[newDoorsArray.length - 1].id));
    dispatch(setDoorVisited({
      doorIndex: newDoorsArray.length -1,
      visited: true,
    }));
  }

  const handleCopyDoor = (doorIndex: number) => {
    if (doors.length >= MAX_DOORS) {
      return;
    }
    dispatch(copyDoor({
      newDoorId: v4(),
      doorIndex,
    }));
  };

  const handleMouseEnter = () => {
    if (!amount) {
      setAmount(1);
    }
  };

  const handleMouseLeave = () => {
    if (!amount) {
      setAmount(1);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedItems = [...doors];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    dispatch(setConfigDoors(reorderedItems));
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {
                doors.map((door, index) =>
                  <Draggable key={door.id} draggableId={door.id} index={index}>
                    {(provided) => {
                      return (
                        <div className='draggable-item' ref={provided.innerRef} {...provided.draggableProps}>
                          <Card
                            title={door.name}
                            isSelected={door.id === selectedId}
                            isVisited={door.visited}
                            isEntered={true}
                            onClick={() => handleDoorSelect(door.id)}
                            onClickRemove={handleRemoveDoor}
                            onClickCopy={() => handleCopyDoor(index)}
                            id={`cardId_${door.id}`}
                          />
                          <Tooltip
                            title={t('controls.drag')}
                            arrow
                          >
                            <div
                              className='draggable-item__drag'
                              {...provided.dragHandleProps}
                            >
                              <img
                                src={drag}
                                alt='drag'
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )
                    }}
                  </Draggable>
                )
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddCardButton
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        onAdd={handleAddDoor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        labelText={t('add-button')}
        disabled={doors.length >= MAX_DOORS}
      />
    </Wrapper>
  );
};

export default DoorSelector;
