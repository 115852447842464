import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import TextStepName from "../../UI/Typography/TextStepName";
import TextModalHeading from "../../UI/Typography/TextModalHeading";
import Button from "../../UI/Button/Button";
import svgNext from "../../../assets/img/arrowRight.svg";
import StyledForm from "../../UI/Form/Form";
import {
  setOrderType,
} from "../../../store/contactSlice";
import useAppDispatch from "../../../hooks/useDispatch";
import useAppSelector from "../../../hooks/useSelector";
import Checkbox from "../../UI/Checkbox/Checkbox";
import {AnfrageContactsBox, AnfrageSwitch} from "./styles";
import CheckboxWrap from "../../UI/Checkbox/CheckboxWrap";
import {Typography} from "@mui/material";
import AppLink from '../../UI/Link/AppLink';
import {PRIVACY_POLICY_LINK} from "../../../constants/common";
import SwitchButtons from "../../UI/SwitchButtons";
import {OrderType} from "../../../store/types";
import {OrderOptions} from "../../../constants/anfrage";
import ExistUser from './components/ExistUser';
import NewUser from './components/NewUser';
import useValidation from '../../../hooks/useValidation';
import { useTranslation } from 'react-i18next';
import { LanguageValues } from '../../../constants/language';

interface IProps {
  onSubmit: () => void;
}

const Anfrage = ({ onSubmit }: IProps) => {
  const { t } = useTranslation();
  const language = useAppSelector((state) => state.language.value);
  const orderType = useAppSelector((state) => state.contact.orderType);
  const loading = useAppSelector((state) => state.contact.loading);
  const [accept, setAccept] = useState(false);
  const { getValidation } = useValidation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setAccept(false);
  }, [])

  const handleAccept = (e: ChangeEvent<HTMLInputElement>) => {
    setAccept(!!e.target.checked);
  }

  const handleOrderType = (value: string) => {
    dispatch(setOrderType(value as OrderType));
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  }

  const handlePolicy = () => {
    window.open(PRIVACY_POLICY_LINK, '_blank');
  }

  return (
    <div>
      <TextStepName
        sx={{ marginBottom: '28px' }}
      >
        {t('order.title')}
      </TextStepName>

      <AnfrageContactsBox>
        <TextModalHeading
          sx={{ marginBottom: '16px' }}
        >
          {t('order.subtitle')}
        </TextModalHeading>

        <AnfrageSwitch>
          <SwitchButtons
            translation={true}
            currentValue={orderType}
            onChange={handleOrderType}
            options={OrderOptions}
            buttonWidth={240}
          />
        </AnfrageSwitch>

        <StyledForm
          onSubmit={handleSubmit}
        >
          {
            orderType === 'Inquiry'
              ? <NewUser loading={loading}/>
              : <ExistUser loading={loading}/>
          }

          <CheckboxWrap>
            <Checkbox
              checked={accept}
              onChange={handleAccept}
              disabled={loading}
            />
            <Typography>
              {
                language === LanguageValues.DE
                  ? <>
                      {t('order.privacy-policy.part1')}&nbsp;
                      <AppLink onClick={handlePolicy}>
                        {t('order.privacy-policy.link')}
                      </AppLink>&nbsp;
                      {t('order.privacy-policy.part2')}
                    </>
                  : <>
                      {t('order.privacy-policy.part1')}&nbsp;
                      <AppLink onClick={handlePolicy}>
                        {t('order.privacy-policy.link')}
                      </AppLink>
                    </>
              }
            </Typography>
          </CheckboxWrap>

          <Button
            sx={{
              width: '100%',
              marginBottom: '32px',
            }}
            type='submit'
            disabled={!accept || !getValidation || loading}
          >
            {t('order.button')}
            <img
              src={svgNext}
              alt=''
            />
          </Button>
        </StyledForm>
      </AnfrageContactsBox>
    </div>
  );
};

export default Anfrage;
