import {ReactNode} from 'react';
import {Box, styled} from "@mui/material";

const Wrapper = styled(Box)`
  width: 1150px;
  min-height: 630px;
  margin: 160px 16px 16px;
  background: #F5F5F5;
  border-radius: 16px;
  position: relative;
`;

interface IProps {
  children: ReactNode;
  maxHeight?: string;
  minHeight?: string;
}

const WorkArea = ({ children, maxHeight = 'unset', minHeight = '630px' }: IProps) => {
  return (
    <Wrapper
      sx={{
        maxHeight,
        minHeight,
      }}
    >
      { children }
    </Wrapper>
  );
};

export default WorkArea;
